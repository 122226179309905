<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre ó título del documento"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Nombre ó título del documento"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="id === undefined"
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Los archivos adjuntos"
            label-for="files"
          >
            <validation-provider
              #default="{ errors }"
              name="archivos"
              rules="required"
            >
              <b-form-file
                v-model="files"
                multiple
                placeholder="Elija un archivo o suéltelo aquí..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Notas"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Notas"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="arrayFiles.length > 0"
        class="align-content-center mx-auto d-flex flex-column"
      >
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-row>
            <b-col
              v-for="(file, index) in arrayFiles"
              :key="index"
              sm="12"
            >
              <a
                :href="file.value"
                :download="file.value"
                target="_blank"
              >
                File {{ index+1 }}
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('document.customer.store') : accessPermissions('document.customer.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'
import CustomersDocumentService from './services/CustomersDocumentService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'DocumentFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormFile,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      note: '',
      files: null,
      arrayFiles: [],
      required,
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.name = ''
      this.note = ''
      this.files = null
      this.arrayFiles = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('document.customer.show')) {
        await CustomersDocumentService.handleEdit(this.id).then(({ data }) => {
          this.name = data.data.name === null ? '' : data.data.name
          this.note = data.data.note === null ? '' : data.data.note
          this.arrayFiles = data.data.file.length === 0 ? [] : data.data.file
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('customer', this.customer)
          formData.append('name', this.name)
          formData.append('note', this.note)

          if (this.id === undefined && this.accessPermissions('document.customer.store')) {
            if (this.files !== null) {
              if (this.files.length > 0) {
                this.files.map(item => {
                  formData.append('files[]', item)
                })
              }
            }

            CustomersDocumentService.handleStore(formData).then(({ data }) => {
              if (data.data) {
                this.swal('El documento ha sido registrado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('document.customer.update')) {
            CustomersDocumentService.handleUpdate(this.id, formData).then(({ data }) => {
              if (data.data) {
                this.swal('El documento ha sido actualizada correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
