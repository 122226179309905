<template>
  <b-tabs justified>
    <b-tab
      v-if="accessPermissions('customers.show')"
      :active="activeTabName === 'generalData' ? true : false"
      @click="handleActiveTab('generalData')"
    >
      <template #title>
        <feather-icon icon="ClipboardIcon" />
        <span>Datos Generales</span>
      </template>

      <TabGeneralDataComponent
        :customer="id"
        :isActive="activeTabName === 'generalData' ? true : false"
      />
    </b-tab>

    <b-tab
      v-if="accessPermissions('tab.devices.customer.index')"
      :active="activeTabName === 'devices' ? true : false"
      :disabled="id === undefined ? true : false"
      @click="handleActiveTab('devices')"
    >
      <template #title>
        <feather-icon icon="SmartphoneIcon" />
        <span>Dispositivos</span>
      </template>

      <TabDeviceComponent
        v-if="id !== undefined"
        :customer="id"
        :isActive="activeTabName === 'devices' ? true : false"
      />
    </b-tab>

    <b-tab
      v-if="accessPermissions('tab.access.customer.index')"
      :active="activeTabName === 'access' ? true : false"
      :disabled="id === undefined ? true : false"
      @click="handleActiveTab('access')"
    >
      <template #title>
        <feather-icon icon="RadioIcon" />
        <span>Acceso</span>
      </template>

      <TabAccessComponent
        v-if="id !== undefined"
        :customer="id"
        :isActive="activeTabName === 'access' ? true : false"
      />
    </b-tab>

    <b-tab
      v-if="accessPermissions('tab.remote.customer.index')"
      :active="activeTabName === 'remote' ? true : false"
      :disabled="id === undefined ? true : false"
      @click="handleActiveTab('remote')"
    >
      <template #title>
        <feather-icon icon="WifiIcon" />
        <span>Remoto</span>
      </template>

      <TabRemoteComponent
        v-if="id !== undefined"
        :customer="id"
        :isActive="activeTabName === 'remote' ? true : false"
      />
    </b-tab>

    <b-tab
      v-if="accessPermissions('tab.providers.email.customer.index')"
      :active="activeTabName === 'emails' ? true : false"
      :disabled="id === undefined ? true : false"
      @click="handleActiveTab('emails')"
    >
      <template #title>
        <feather-icon icon="MailIcon" />
        <span>Correos</span>
      </template>

      <TabMailComponent
        v-if="id !== undefined"
        :customer="id"
        :isActive="activeTabName === 'emails' ? true : false"
      />
    </b-tab>

    <b-tab
      v-if="accessPermissions('tab.notes.customer.index')"
      :active="activeTabName === 'notes' ? true : false"
      :disabled="id === undefined ? true : false"
      @click="handleActiveTab('notes')"
    >
      <template #title>
        <feather-icon icon="EditIcon" />
        <span>Notas</span>
      </template>

      <TabNoteComponent
        v-if="id !== undefined"
        :customer="id"
        :isActive="activeTabName === 'notes' ? true : false"
      />
    </b-tab>

    <b-tab
      v-if="accessPermissions('tab.servers.customer.index')"
      :active="activeTabName === 'servers' ? true : false"
      :disabled="id === undefined ? true : false"
      @click="handleActiveTab('servers')"
    >
      <template #title>
        <feather-icon icon="ServerIcon" />
        <span>Servidores</span>
      </template>

      <TabServerComponent
        v-if="id !== undefined"
        :customer="id"
        :isActive="activeTabName === 'servers' ? true : false"
      />
    </b-tab>

    <b-tab
      v-if="accessPermissions('tab.documents.customer.index')"
      :active="activeTabName === 'documents' ? true : false"
      :disabled="id === undefined ? true : false"
      @click="handleActiveTab('documents')"
    >
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Documentos</span>
      </template>

      <TabDocumentComponent
        v-if="id !== undefined"
        :customer="id"
        :isActive="activeTabName === 'documents' ? true : false"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue'
import TabGeneralDataComponent from './TabGeneralDataComponent.vue'
import TabDeviceComponent from './TabDeviceComponent.vue'
import TabAccessComponent from './TabAccessComponent.vue'
import TabRemoteComponent from './TabRemoteComponent.vue'
import TabMailComponent from './TabMailComponent.vue'
import TabServerComponent from './TabServerComponent.vue'
import TabDocumentComponent from './TabDocumentComponent.vue'
import TabNoteComponent from './TabNoteComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'TabsComponent',
  components: {
    BTabs,
    BTab,
    TabGeneralDataComponent,
    TabDeviceComponent,
    TabAccessComponent,
    TabRemoteComponent,
    TabMailComponent,
    TabServerComponent,
    TabDocumentComponent,
    TabNoteComponent,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      activeTabName: 'generalData',
    }
  },
  watch: {
    id() {
    },
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    handleActiveTab(value) {
      this.activeTabName = value
    },
  },
}
</script>

<style scoped>

</style>
