<template>
  <CustomersContactComponent
    :id="id"
    :isActive="isActive"
  />
</template>

<script>
import CustomersContactComponent from '../../CustomersContactComponent.vue'

export default {
  name: 'ContactComponent',
  components: {
    CustomersContactComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    id() {
    },
    isActive() {
    },
  },
}
</script>

<style scoped>

</style>
