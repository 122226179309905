<template>
  <CustomerListComponent
    :customer="customer"
    :isActive="isActive"
    root="branches"
  />
</template>

<script>
import CustomerListComponent from '../../CustomerListComponent.vue'

export default {
  name: 'BranchComponent',
  components: {
    CustomerListComponent,
  },
  props: {
    customer: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    customer() {
    },
    isActive() {
    },
  },
}
</script>
