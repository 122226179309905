<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          v-if="id !== undefined"
          sm="12"
          class="align-content-right d-flex flex-column"
        >
          <div class="position-relative d-inline text-right">
            <h6>Creado: </h6>
          </div>
          <div class="position-relative d-inline text-right">
            <h4><strong>{{ created_at }}</strong></h4>
          </div>
        </b-col>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="descripción"
              rules="required"
            >
              <quill-editor
                id="description"
                v-model="description"
                :options="editorOption"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('note.customer.store') : accessPermissions('note.customer.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import CustomersNoteService from './services/CustomersNoteService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import moment from 'moment'

export default {
  name: 'NoteFormComponent',
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: ['serif', 'monospace'] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
          ],
        },
        theme: 'snow',
        placeholder: 'Inserte texto aquí...',
      },
      description: '',
      created_at: '',
      required,
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.description = ''
      this.created_at = ''
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('note.customer.show')) {
        await CustomersNoteService.handleEdit(this.id).then(({ data }) => {
          const dateCreated = data.data.created_at.split(' ')
          this.created_at = moment(dateCreated[0]).format('DD/MM/YYYY')
          this.description = data.data.description === null ? '' : data.data.description
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            customer: this.customer,
            description: this.description,
          }

          if (this.id === undefined && this.accessPermissions('note.customer.store')) {
            CustomersNoteService.handleStore(param).then(({ data }) => {
              if (data.data) {
                this.swal('La nota ha sido registrada correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('note.customer.update')) {
            CustomersNoteService.handleUpdate(this.id, param).then(({ data }) => {
              if (data.data) {
                this.swal('La nota ha sido actualizada correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
