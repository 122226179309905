<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                v-model="name"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Usuario"
            label-for="user"
          >
            <validation-provider
              #default="{ errors }"
              name="usuario"
              rules="required"
            >
              <b-form-input
                v-model="user"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Usuario"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="contraseña"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nota"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Nota"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('user.customer.store') : accessPermissions('user.customer.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import CustomersUserService from './services/CustomersUserService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'UserFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: '',
      user: '',
      password: '',
      note: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.name = ''
      this.user = ''
      this.password = ''
      this.note = ''
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('user.customer.show')) {
        await CustomersUserService.handleEdit(this.id).then(({ data }) => {
          this.name = data.data.name === null ? '' : data.data.name
          this.user = data.data.user === null ? '' : data.data.user
          this.password = data.data.password === null ? '' : data.data.password
          this.note = data.data.note === null ? '' : data.data.note
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            customer: this.customer,
            name: this.name,
            user: this.user,
            password: this.password,
            note: this.note,
          }

          if (this.id === undefined && this.accessPermissions('user.customer.store')) {
            CustomersUserService.handleStore(param).then(({ data }) => {
              if (data.data) {
                this.swal('El usuario ha sido registrado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('user.customer.update')) {
            CustomersUserService.handleUpdate(this.id, param).then(({ data }) => {
              if (data.data) {
                this.swal('El usuario ha sido actualizado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
