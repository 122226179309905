import customerDefaultConfig from './CustomersEmailDefaultConfig'
import axiosIns from '@/libs/axios'

class CustomersEmailService {
  config = { ...customerDefaultConfig }
  
  axios = null
  
  constructor() {
    this.axios = axiosIns
  }
  
  async handleList(customer) {
    const result = await this.axios.get(`${this.config.indexEndpoint}${customer}`)
    
    return result
  }
  
  async handleStore(data) {
    const result = await this.axios.post(this.config.storeEndpoint, data)
    
    return result
  }
  
  async handleEdit(id) {
    const result = await this.axios.get(`${this.config.editEndpoint}${id}`)
    
    return result
  }
  
  async handleUpdate(id, data) {
    const result = await this.axios.put(`${this.config.updateEndpoint}${id}`, data)
    
    return result
  }
  
  async handleDelete(id) {
    const result = await this.axios.delete(`${this.config.deleteEndpoint}${id}`)
    
    return result
  }
}

export default new CustomersEmailService()
