<template>
  <FormCreateComponent
    :id="customer"
    :isActive="isActive"
    root="customers"
    endPoint="customers"
  />
</template>

<script>
import FormCreateComponent from '../../FormCreateComponent.vue'

export default {
  name: 'DataComponent',
  components: {
    FormCreateComponent,
  },
  props: {
    customer: {
      type: Number,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    customer() {
    },
    isActive() {
    },
  },
}
</script>

<style scoped>
</style>
