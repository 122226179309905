<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Proveedor"
            label-for="provider"
          >
            <validation-provider
              #default="{ errors }"
              name="proveedor"
              rules="required"
            >
              <v-select
                id="provider"
                v-model="provider"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                label="name"
                :options="rowsProviders"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                v-model="name"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Email"
            label-for="emailValue"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="contraseña"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre opcional"
            label-for="nameOptional"
          >
            <b-form-input
              v-model="nameOptional"
              type="text"
              placeholder="Nombre opcional"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nota"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Comentario"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('providers.customer.store') : accessPermissions('providers.customer.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ProvidersService from '@/pages/dashboard/designers/providers/services/ProvidersService'
import CustomersEmailService from './services/CustomersEmailService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'MailFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      provider: '',
      name: '',
      emailValue: '',
      password: '',
      nameOptional: '',
      note: '',
      rowsProviders: [],
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    async id() {
      await this.onHandleProvidersList()
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleProvidersList()
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleProvidersList()
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.provider = ''
      this.name = ''
      this.emailValue = ''
      this.password = ''
      this.nameOptional = ''
      this.note = ''
      this.rowsProviders = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleProvidersList() {
      await ProvidersService.handleList().then(({ data }) => {
        this.rowsProviders = data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('providers.customer.show')) {
        await CustomersEmailService.handleEdit(this.id).then(({ data }) => {
          let provider = ''

          if (data.data.provider_design_id !== null) {
            provider = {
              id: data.data.provider_design_id,
              name: data.data.provider_design,
            }
          }

          this.provider = provider
          this.name = data.data.name === null ? '' : data.data.name
          this.emailValue = data.data.email === null ? '' : data.data.email
          this.password = data.data.password === null ? '' : data.data.password
          this.nameOptional = data.data.name_optional === null ? '' : data.data.name_optional
          this.note = data.data.note === null ? '' : data.data.note
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            customer: this.customer,
            provider_design_id: this.provider.id,
            name: this.name,
            email: this.emailValue,
            password: this.password,
            name_optional: this.nameOptional,
            note: this.note,
          }

          if (this.id === undefined && this.accessPermissions('providers.customer.store')) {
            CustomersEmailService.handleStore(param).then(({ data }) => {
              if (data.data) {
                this.swal('El proveedor de correo ha sido registrado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('providers.customer.update')) {
            CustomersEmailService.handleUpdate(this.id, param).then(({ data }) => {
              if (data.data) {
                this.swal('El proveedor de correo ha sido actualizado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
