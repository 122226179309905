<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Tipo de dispositivo"
            label-for="device"
          >
            <validation-provider
              #default="{ errors }"
              name="tipo de dispositivo"
              rules="required"
            >
              <v-select
                v-model="device"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                :options="rowsDevices"
                label="name"
                @input="onChangeDevice"
              >
                <span
                  v-if="rowsDevices.length > 0"
                  slot="no-options"
                >
                  No hay data para cargar
                </span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Marca"
            label-for="brand"
          >
            <validation-provider
              #default="{ errors }"
              name="marca"
              rules="required"
            >
              <v-select
                v-model="brand"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                :options="rowsBrands"
                label="name"
              >
                <span
                  v-if="rowsBrands.length > 0"
                  slot="no-options"
                >
                  No hay data para cargar
                </span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="IP"
            label-for="ip"
          >
            <validation-provider
              #default="{ errors }"
              name="ip"
              rules="required"
            >
              <b-form-input
                id="ip"
                v-model="ip"
                placeholder="IP"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Características"
            label-for="features"
          >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="fields.length > 0"
        class="align-content-center mx-auto d-flex flex-column"
      >
        <b-col
          v-for="(input, index) in fields"
          :key="`fieldsInput-${index}`"
          lg="8"
          md="10"
          sm="12"
        >
          <b-row>
            <b-col
              lg="5"
              md="5"
              sm="5"
              style="margin-bottom: 10px;"
            >
              <b-form-input
                type="text"
                v-model="input.item"
                :placeholder="`Item ${index+1}`"
              />
            </b-col>
            <b-col
              lg="5"
              md="5"
              sm="5"
            >
              <b-form-input
                type="text"
                v-model="input.value"
                :placeholder="`Valor ${index+1}`"
              />
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="2"
              class="text-right"
            >
              <div class="d-inline-block position-relative">
                <button
                  v-if="fields.length > 0"
                  class="btn btn-md btn-danger width-40px m-0 p-0"
                  type="button"
                  @click="removeField(index)"
                >
                  <feather-icon
                    class="position-relative mx-auto text-center justify-content-center d-block"
                    icon="MinusIcon"
                  />
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column mb-1">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="btn btn-md btn-success width-40px m-0 p-0"
            type="button"
            @click="addField"
          >
            <feather-icon
              class="position-relative mx-auto text-center justify-content-center d-block"
              icon="PlusIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column mb-1">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('device.customer.store') : accessPermissions('device.customer.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import BrandsService from '@/pages/dashboard/designers/brands/services/BrandsService'
import DevicesService from '@/pages/dashboard/designers/devices/services/DevicesService'
import CustomersDeviceService from './services/CustomersDeviceService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'DeviceFormComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      device: '',
      brand: '',
      ip: '',
      name: '',
      rowsDevices: [],
      rowsBrands: [],
      required,
    }
  },
  watch: {
    async id() {
      await this.onHandleDevicesList()
      await this.onHandleBrandsList()
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleDevicesList()
      await this.onHandleBrandsList()
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleDevicesList()
    await this.onHandleBrandsList()
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    addField() {
      this.fields.push({ item: '', value: '' })
    },
    removeField(number) {
      this.fields.forEach((item, index, map) => {
        if (index === number) map.splice(index, 1)
      })
    },
    clearForm() {
      this.device = ''
      this.brand = ''
      this.ip = ''
      this.name = ''
      this.rowsDevices = []
      this.rowsBrands = []
      this.fields = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('device.customer.show')) {
        await CustomersDeviceService.handleEdit(this.id).then(({ data }) => {
          let device = ''
          let brand = ''

          if (data.data.device_design_id !== null) {
            device = {
              id: data.data.device_design_id,
              name: data.data.devices.name,
            }
          }

          if (data.data.brand_design_id !== null) {
            brand = {
              id: data.data.brand_design_id,
              name: data.data.brands.name,
            }
          }

          this.device = device
          this.brand = brand
          this.name = data.data.name === null ? '' : data.data.name
          this.ip = data.data.ip === null ? '' : data.data.ip
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleDevicesList() {
      await DevicesService.handleList().then(({ data }) => {
        this.rowsDevices = data.data
      }).catch(error => this.errorResp(error))
    },
    async onChangeDevice(event) {
      if (event.id !== null) {
        await DevicesService.handleEdit(event.id).then(({ data }) => {
          this.fields = data.data.fields === null ? this.fields : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleBrandsList() {
      await BrandsService.handleList().then(({ data }) => {
        this.rowsBrands = data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const data = {
            customer: this.customer,
            device_design_id: this.device.id,
            brand_design_id: this.brand.id,
            name: this.name,
            ip: this.ip,
            fields: this.fields.length === 0 ? '' : this.fields,
          }

          if (this.id === undefined && this.accessPermissions('device.customer.store')) {
            CustomersDeviceService.handleStore(data).then(({ data }) => {
              if (data.data) {
                this.swal('El dispositivo ha sido registrado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('device.customer.update')) {
            CustomersDeviceService.handleUpdate(this.id, data).then(({ data }) => {
              if (data.data) {
                this.swal('El dispositivo ha sido actualizado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
