<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label=""
            label-for="has_register"
          >
            <b-form-checkbox
              v-model="has_register"
              class="custom-control-primary"
            >
              Registrar proveedor
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
          v-if="has_register"
        >
          <b-form-group
            label="Nombre del proveedor"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre del proveedor"
              rules="required"
            >
              <b-form-input
                v-model="name"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Nombre del proveedor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
          v-if="!has_register"
        >
          <b-form-group
            label="Proveedor"
            label-for="service_design_id"
          >
            <validation-provider
              #default="{ errors }"
              name="proveedor"
              rules="required"
            >
              <v-select
                id="service_design_id"
                v-model="service_design_id"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                label="name"
                :options="rowsServices"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Número de contrato"
            label-for="document"
          >
            <validation-provider
              #default="{ errors }"
              name="número de contrato"
              rules="required"
            >
              <b-form-input
                v-model="document"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Número de contrato"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <b-form-input
              v-model="phone"
              type="number"
              placeholder="Teléfono"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Comentario"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Comentario"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('providers.services.customer.store') : accessPermissions('providers.services.customer.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import ServicesService from '@/pages/dashboard/designers/providers/services/ServicesService'
import CustomersProviderService from './services/CustomersProviderService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'ProviderFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      has_register: false,
      name: '',
      service_design_id: '',
      document: '',
      phone: '',
      note: '',
      rowsServices: [],
      required,
    }
  },
  watch: {
    async id() {
      await this.onHandleProvidersList()
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleProvidersList()
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleProvidersList()
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.has_register = false
      this.name = ''
      this.service_design_id = ''
      this.document = ''
      this.phone = ''
      this.note = ''
      this.rowsServices = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleProvidersList() {
      await ServicesService.handleList().then(({ data }) => {
        this.rowsServices = data.data.length === 0 ? [] : data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('providers.services.customer.show')) {
        await CustomersProviderService.handleEdit(this.id).then(({ data }) => {
          let service = ''

          if (data.data.service_design_id !== null) {
            service = {
              id: data.data.service_design_id,
              name: data.data.service_design,
            }
          }

          this.service_design_id = service
          this.document = data.data.document === null ? '' : data.data.document
          this.phone = data.data.phone === null ? '' : data.data.phone
          this.note = data.data.note === null ? '' : data.data.note
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          if (this.has_register) {
            const param = {
              name: this.name,
            }

            ServicesService.handleStore(param).then(({ data }) => {
              if (data.data) {
                const obj = {
                  customer: this.customer,
                  service_design_id: data.data.id,
                  document: this.document,
                  phone: this.phone,
                  note: this.note,
                }

                if (this.id === undefined && this.accessPermissions('providers.services.customer.store')) {
                  CustomersProviderService.handleStore(obj).then(({ data }) => {
                    if (data.data) {
                      this.swal('El proveedor ha sido registrado correctamente', 'success')
                      this.closeForm()
                    }
                  }).catch(error => this.errorResp(error))
                } else if (this.id !== undefined && this.accessPermissions('providers.services.customer.update')) {
                  CustomersProviderService.handleUpdate(this.id, obj).then(({ data }) => {
                    if (data.data) {
                      this.swal('El proveedor ha sido actualizado correctamente', 'success')
                      this.closeForm()
                    }
                  }).catch(error => this.errorResp(error))
                }
              }
            }).catch(error => this.errorResp(error))
          } else {
            const param = {
              customer: this.customer,
              service_design_id: this.service_design_id.id,
              document: this.document,
              phone: this.phone,
              note: this.note,
            }

            if (this.id === undefined) {
              CustomersProviderService.handleStore(param).then(({ data }) => {
                if (data.data) {
                  this.swal('El proveedor ha sido registrado correctamente', 'success')
                  this.closeForm()
                }
              }).catch(error => this.errorResp(error))
            } else if (this.id !== undefined) {
              CustomersProviderService.handleUpdate(this.id, param).then(({ data }) => {
                if (data.data) {
                  this.swal('El proveedor ha sido actualizado correctamente', 'success')
                  this.closeForm()
                }
              }).catch(error => this.errorResp(error))
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
