import { render, staticRenderFns } from "./RemoteComponent.vue?vue&type=template&id=e8379cb4&scoped=true&"
import script from "./RemoteComponent.vue?vue&type=script&lang=js&"
export * from "./RemoteComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8379cb4",
  null
  
)

export default component.exports