<template>
  <b-row>
    <b-col cols="12">
      <MailComponent
        :customer="customer"
        :isActive="isActive"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import MailComponent from './Mail/MailComponent.vue'

export default {
  name: 'TabMailComponent',
  components: {
    BRow,
    BCol,
    MailComponent,
  },
  props: {
    customer: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    customer() {
    },
    isActive() {
    },
  },
}
</script>

<style scoped>

</style>
