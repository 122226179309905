<template>
  <b-row>
    <b-col cols="12">
      <b-tabs>
        <b-tab
          v-if="accessPermissions('tab.servers.servers.customer.index')"
          :active="(isActive && activeTabName === 'server') ? true : false"
          @click="handleActiveTab('server')"
        >
          <template #title>
            <span>Servidores</span>
          </template>

          <ServerComponent
            :customer="customer"
            :isActive="(isActive && activeTabName === 'server') ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('tab.servers.users.customer.index')"
          :active="activeTabName === 'users' ? true : false"
          @click="handleActiveTab('users')"
        >
          <template #title>
            <span>Usuarios</span>
          </template>

          <UserComponent
            :customer="customer"
            :isActive="activeTabName === 'users' ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('tab.servers.instance.customer.index')"
          :active="activeTabName === 'instance' ? true : false"
          @click="handleActiveTab('instance')"
        >
          <template #title>
            <span>Instancias</span>
          </template>

          <InstanceComponent
            :customer="customer"
            :isActive="activeTabName === 'instance' ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('tab.servers.rdps.customer.index')"
          :active="activeTabName === 'rdps' ? true : false"
          @click="handleActiveTab('rdps')"
        >
          <template #title>
            <span>RDPS</span>
          </template>

          <RdpsComponent
            :customer="customer"
            :isActive="activeTabName === 'rdps' ? true : false"
          />
        </b-tab>

        <b-tab
          v-if="accessPermissions('tab.servers.firewall.customer.index')"
          :active="activeTabName === 'firewall' ? true : false"
          @click="handleActiveTab('firewall')"
        >
          <template #title>
            <span>Firewalls</span>
          </template>

          <FirewallComponent
            :customer="customer"
            :isActive="activeTabName === 'firewall' ? true : false"
          />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import ServerComponent from './Server/Server/ServerComponent.vue'
import UserComponent from './Server/User/UserComponent.vue'
import InstanceComponent from './Server/Instance/InstanceComponent.vue'
import RdpsComponent from './Server/Rdps/RdpsComponent.vue'
import FirewallComponent from './Server/Firewall/FirewallComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'TabTabServerComponent',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    ServerComponent,
    UserComponent,
    InstanceComponent,
    RdpsComponent,
    FirewallComponent,
  },
  props: {
    customer: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeTabName: 'server',
    }
  },
  watch: {
    customer() {
    },
    async isActive() {
      await this.handleActiveTab(this.activeTabName)
    },
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    handleActiveTab(value) {
      this.activeTabName = value
    },
  },
}
</script>

<style scoped>

</style>
