<template>
  <div>
    <b-row>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-start">
          <b-button
            v-if="accessPermissions('remote.customer.store')"
            v-b-modal.modal-remote
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Nuevo</span>
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-form-input
              v-if="accessPermissions('remote.customer.list.index')"
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar..."
              class="d-inline-block"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="accessPermissions('remote.customer.list.index')">
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :items="rows"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(name)="data">
            <div class="text-wrap">
              {{ data.item.name }}
            </div>
          </template>

          <template #cell(listRemotes)="data">
            <div class="text-wrap">
              {{ data.item.listRemotes }}
            </div>
          </template>

          <template #cell(action)="data">
            <b-dropdown
              v-if="accessPermissions('remote.customer.update') || accessPermissions('remote.customer.destroy')"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="SettingsIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="accessPermissions('remote.customer.update')"
                @click="onHandleEdit(data.item.id)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="accessPermissions('remote.customer.destroy')"
                @click="onHandleDelete(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="accessPermissions('remote.customer.list.index')">
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            label="Por Página"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-if="id === undefined ? accessPermissions('remote.customer.store') : accessPermissions('remote.customer.update')"
      id="modal-remote"
      ref="remote-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Asociar remoto a este cliente"
      @hidden="hide"
    >
      <RemoteFormComponent
        :id="id"
        :customer="customer"
        @setHiddenModal="hide"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
  BModal,
} from 'bootstrap-vue'
import RemoteFormComponent from './RemoteFormComponent.vue'
import CustomersRemoteService from './services/CustomersRemoteService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import { getItem, setItem } from '@/libs/storage'

export default {
  name: 'RemoteComponent',
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BModal,
    RemoteFormComponent,
  },
  props: {
    customer: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 20, 50, 100],
      totalRows: 0,
      currentPage: 1,
      filter: null,
      fields: [
        {
          key: 'name', label: 'Nombre',
        },
        {
          key: 'listRemotes', label: 'Acceso',
        },
        {
          key: 'action', label: 'Acción',
        },
      ],
      rows: [],
      id: undefined,
    }
  },
  watch: {
    filter(value) {
      setItem('search_customers_remotes', value)
    },
    async customer() {
      await this.onHandleList()
    },
    async isActive() {
      await this.onHandleList()
    },
  },
  mounted() {
    this.filter = getItem('search_customers_remotes') === undefined ? '' : getItem('search_customers_remotes')
  },
  async created() {
    await this.onHandleList()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    hide() {
      this.id = undefined
      this.onHandleList()
      this.$refs['remote-modal'].hide()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onHandleEdit(id) {
      if (this.accessPermissions('remote.customer.update')) {
        this.id = id
        this.$refs['remote-modal'].show()
      }
    },
    async onHandleDelete(id) {
      if (this.accessPermissions('remote.customer.destroy')) {
        await this.$swal({
          title: '¿Está seguro que desea eliminar el remoto?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__bounceIn',
          },
          buttonsStyling: false,
        }).then(({ value }) => {
          if (value) {
            CustomersRemoteService.handleDelete(id).then(({ data }) => {
              if (data.data) {
                this.onHandleList()
              }
            }).catch(error => this.errorResp(error))
          }
        })
      }
    },
    async onHandleList() {
      if (this.isActive && this.customer !== undefined && this.accessPermissions('remote.customer.index')) {
        await CustomersRemoteService.handleList(this.customer).then(({ data }) => {
          let arrayList = []

          if (data.data.length > 0) {
            const array = data.data

            array.map(({ remote_design_id, remotes }, index) => {
              array[index].listRemotes = remote_design_id === null ? '' : remotes.name
            })

            arrayList = array
          }

          this.rows = arrayList.length === 0 ? [] : arrayList
          this.totalRows = arrayList.length
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>

</style>
