<template>
  <b-card-code>
    <b-row>
      <b-col
        v-if="id !== undefined"
        cols="12"
        class="mb-2"
      >
        <h2>{{ fullName }}</h2>
      </b-col>
      <b-col cols="12">
        <TabsComponent
          :id="id"
        />
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import TabsComponent from './components/TabsComponent.vue'
import permissions from '@/libs/permissions'
import errorsServices from '@/libs/errorsServices'
import CustomersService from '@/pages/dashboard/customers/services/CustomersService'

export default {
  name: 'CustomerEditPage',
  components: {
    BRow,
    BCol,
    BCardCode,
    TabsComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fullName: '',
    }
  },
  watch: {
    id() {
      this.onHandleEdit()
    },
  },
  created() {
    if (this.id !== undefined && this.accessPermissions('customers.show')) {
      this.onHandleEdit()
    }
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('customers.show')) {
        await CustomersService.handleEdit(this.id).then(({ data }) => {
          this.fullName = data.data.full_name
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>
