<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Nombre del servidor"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre del servidor"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Nombre del servidor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Ip pública"
            label-for="ip_public"
          >
            <b-form-input
              id="ip_public"
              v-model="ip_public"
              placeholder="Ip pública"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Ip privada"
            label-for="ip_private"
          >
            <b-form-input
              id="ip_private"
              v-model="ip_private"
              placeholder="Ip privada"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Sistema operativo"
            label-for="so"
          >
            <b-form-input
              id="so"
              v-model="so"
              placeholder="Sistema operativo"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Memoria"
            label-for="memory"
          >
            <b-form-input
              id="memory"
              v-model="memory"
              placeholder="Memoria"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Disco"
            label-for="disc"
          >
            <b-form-input
              id="disc"
              v-model="disc"
              placeholder="Disco"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Modelo del servidor"
            label-for="model"
          >
            <b-form-input
              id="model"
              v-model="model"
              placeholder="Modelo del servidor"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group
            label="RDWeb"
            label-for="rdweb"
          >
            <b-form-input
              id="rdweb"
              v-model="rdweb"
              placeholder="RDWeb"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Dominio"
            label-for="domain"
          >
            <b-form-input
              id="domain"
              v-model="domain"
              placeholder="Dominio"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="CPU"
            label-for="cpu"
          >
            <b-form-input
              id="cpu"
              v-model="cpu"
              placeholder="CPU"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group
            label="Notas"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Notas"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="fields.length > 0"
      >
        <b-col
          v-for="(input, index) in fields"
          :key="`fieldsInput-${index}`"
          lg="12"
          md="12"
          sm="12"
        >
          <b-row>
            <b-col
              lg="5"
              md="5"
              sm="5"
              style="margin-bottom: 10px;"
            >
              <b-form-input
                type="text"
                v-model="input.item"
                :placeholder="`Item ${index+1}`"
              />
            </b-col>
            <b-col
              lg="5"
              md="5"
              sm="5"
            >
              <b-form-input
                type="text"
                v-model="input.value"
                :placeholder="`Valor ${index+1}`"
              />
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="2"
              class="text-right"
            >
              <div class="d-inline-block position-relative">
                <button
                  v-if="fields.length > 0"
                  class="btn btn-md btn-danger width-40px m-0 p-0"
                  type="button"
                  @click="removeField(index)"
                >
                  <feather-icon
                    class="position-relative mx-auto text-center justify-content-center d-block"
                    icon="MinusIcon"
                  />
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="btn btn-md btn-success width-40px m-0 p-0"
            type="button"
            @click="addField"
          >
            <feather-icon
              class="position-relative mx-auto text-center justify-content-center d-block"
              icon="PlusIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('server.customer.store') : accessPermissions('server.customer.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import CustomersServerService from './services/CustomersServerService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'ServerFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      name: '',
      ip_public: '',
      ip_private: '',
      so: '',
      memory: '',
      disc: '',
      model: '',
      rdweb: '',
      domain: '',
      cpu: '',
      note: '',
      required,
    }
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    addField() {
      this.fields.push({ item: '', value: '' })
    },
    removeField(number) {
      this.fields.forEach((item, index, map) => {
        if (index === number) map.splice(index, 1)
      })
    },
    clearForm() {
      this.name = ''
      this.ip_public = ''
      this.ip_private = ''
      this.so = ''
      this.memory = ''
      this.disc = ''
      this.model = ''
      this.rdweb = ''
      this.domain = ''
      this.cpu = ''
      this.note = ''
      this.fields = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('server.customer.show')) {
        await CustomersServerService.handleEdit(this.id).then(({ data }) => {
          this.name = data.data.name === null ? '' : data.data.name
          this.ip_public = data.data.ip_public === null ? '' : data.data.ip_public
          this.ip_private = data.data.ip_private === null ? '' : data.data.ip_private
          this.so = data.data.so === null ? '' : data.data.so
          this.memory = data.data.memory === null ? '' : data.data.memory
          this.disc = data.data.disc === null ? '' : data.data.disc
          this.model = data.data.model === null ? '' : data.data.model
          this.rdweb = data.data.rdweb === null ? '' : data.data.rdweb
          this.domain = data.data.domain === null ? '' : data.data.domain
          this.cpu = data.data.cpu === null ? '' : data.data.cpu
          this.note = data.data.note === null ? '' : data.data.note
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            customer: this.customer,
            name: this.name,
            ip_public: this.ip_public,
            ip_private: this.ip_private,
            so: this.so,
            memory: this.memory,
            disc: this.disc,
            model: this.model,
            rdweb: this.rdweb,
            domain: this.domain,
            cpu: this.cpu,
            note: this.note,
            fields: this.fields.length === 0 ? '' : this.fields,
          }

          if (this.id === undefined && this.accessPermissions('server.customer.store')) {
            CustomersServerService.handleStore(param).then(({ data }) => {
              if (data.data) {
                this.swal('El server ha sido registrado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('server.customer.update')) {
            CustomersServerService.handleUpdate(this.id, param).then(({ data }) => {
              if (data.data) {
                this.swal('El server ha sido actualizado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
