var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-row',{staticClass:"align-content-center mx-auto d-flex flex-column"},[_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Proveedor","label-for":"provider"}},[_c('validation-provider',{attrs:{"name":"proveedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"provider","state":errors.length > 0 ? false:null,"dir":'ltr',"label":"name","options":_vm.rowsProviders},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"Nombre"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"emailValue"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre opcional","label-for":"nameOptional"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Nombre opcional"},model:{value:(_vm.nameOptional),callback:function ($$v) {_vm.nameOptional=$$v},expression:"nameOptional"}})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nota","label-for":"note"}},[_c('b-form-textarea',{attrs:{"id":"note","placeholder":"Comentario","rows":"3"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[(_vm.id === undefined ? _vm.accessPermissions('providers.customer.store') : _vm.accessPermissions('providers.customer.update'))?_c('b-button',{staticClass:"float-right text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onHandleValidationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Guardar ")],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"secondary"},on:{"click":_vm.closeForm}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }