<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Servidor"
            label-for="server"
          >
            <validation-provider
              #default="{ errors }"
              name="servidor"
              rules="required"
            >
              <v-select
                id="server"
                v-model="server"
                :state="errors.length > 0 ? false:null"
                :dir="'ltr'"
                label="name"
                :options="rowsServers"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Acción"
            label-for="action"
          >
            <validation-provider
              #default="{ errors }"
              name="acción"
              rules="required"
            >
              <b-form-input
                id="action"
                v-model="action"
                placeholder="Acción"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="IP"
            label-for="ip"
          >
            <validation-provider
              #default="{ errors }"
              name="ip"
              rules="required"
            >
              <b-form-input
                id="ip"
                v-model="ip"
                placeholder="IP"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Protocolo"
            label-for="protocol"
          >
            <validation-provider
              #default="{ errors }"
              name="protocol"
              rules="required"
            >
              <b-form-input
                id="protocol"
                v-model="protocol"
                placeholder="Protocolo"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Puerto"
            label-for="port"
          >
            <validation-provider
              #default="{ errors }"
              name="puerto"
              rules="required"
            >
              <b-form-input
                id="port"
                v-model="port"
                placeholder="Puerto"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Notas"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="note"
              placeholder="Notas"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              placeholder="Descripción"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="fields.length > 0"
      >
        <b-col
          v-for="(input, index) in fields"
          :key="`fieldsInput-${index}`"
          lg="12"
          md="12"
          sm="12"
        >
          <b-row>
            <b-col
              lg="5"
              md="5"
              sm="5"
              style="margin-bottom: 10px;"
            >
              <b-form-input
                type="text"
                v-model="input.item"
                :placeholder="`Item ${index+1}`"
              />
            </b-col>
            <b-col
              lg="5"
              md="5"
              sm="5"
            >
              <b-form-input
                type="text"
                v-model="input.value"
                :placeholder="`Valor ${index+1}`"
              />
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="2"
              class="text-right"
            >
              <div class="d-inline-block position-relative">
                <button
                  v-if="fields.length > 0"
                  class="btn btn-md btn-danger width-40px m-0 p-0"
                  type="button"
                  @click="removeField(index)"
                >
                  <feather-icon
                    class="position-relative mx-auto text-center justify-content-center d-block"
                    icon="MinusIcon"
                  />
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class="btn btn-md btn-success width-40px m-0 p-0"
            type="button"
            @click="addField"
          >
            <feather-icon
              class="position-relative mx-auto text-center justify-content-center d-block"
              icon="PlusIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-button
            v-if="(id === undefined && accessPermissions('server.firewall.customer.store')) || (id !== undefined && accessPermissions('server.firewall.customer.update'))"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="closeForm"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import CustomersServerService from '../Server/services/CustomersServerService'
import CustomersServerFirewallService from './services/CustomersServerFirewallService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FirewallFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      server: '',
      action: '',
      ip: '',
      protocol: '',
      port: '',
      description: '',
      note: '',
      rowsServers: [],
      required,
    }
  },
  watch: {
    async id() {
      await this.onHandleServerList()
      await this.onHandleEdit()
    },
    async customer() {
      await this.onHandleServerList()
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleServerList()
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    addField() {
      this.fields.push({ item: '', value: '' })
    },
    removeField(number) {
      this.fields.forEach((item, index, map) => {
        if (index === number) map.splice(index, 1)
      })
    },
    clearForm() {
      this.server = ''
      this.action = ''
      this.ip = ''
      this.protocol = ''
      this.port = ''
      this.description = ''
      this.note = ''
      this.rowsServers = []
      this.fields = []
      this.$refs.formValidation.reset()
    },
    closeForm() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleServerList() {
      if (this.customer !== undefined) {
        await CustomersServerService.handleList(this.customer).then(({ data}) => {
          this.rowsServers = data.data
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('server.firewall.customer.show')) {
        await CustomersServerFirewallService.handleEdit(this.id).then(({ data }) => {
          let server = ''

          if (data.data.server_id !== null) {
            server = {
              id: data.data.server_id,
              name: data.data.server.name,
            }
          }

          this.server = server
          this.action = data.data.action === null ? '' : data.data.action
          this.ip = data.data.ip === null ? '' : data.data.ip
          this.protocol = data.data.protocol === null ? '' : data.data.protocol
          this.port = data.data.port === null ? '' : data.data.port
          this.description = data.data.description === null ? '' : data.data.description
          this.note = data.data.note === null ? '' : data.data.note
          this.fields = data.data.fields === null ? [] : data.data.fields
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const param = {
            customer: this.customer,
            server_id: this.server.id,
            action: this.action,
            ip: this.ip,
            protocol: this.protocol,
            port: this.port,
            description: this.description,
            note: this.note,
            fields: this.fields.length === 0 ? '' : this.fields,
          }

          if (this.id === undefined && this.accessPermissions('server.firewall.customer.store')) {
            CustomersServerFirewallService.handleStore(param).then(({ data }) => {
              if (data.data) {
                this.swal('El firewall ha sido registrado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('server.firewall.customer.update')) {
            CustomersServerFirewallService.handleUpdate(this.id, param).then(({ data }) => {
              if (data.data) {
                this.swal('El firewall ha sido actualizado correctamente', 'success')
                this.closeForm()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.width-40px {
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  position: relative !important;
}
</style>
